<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>內容管理中心</b-breadcrumb-item>
            <b-breadcrumb-item active>表單管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-2 mb-2 mb-xl-0 font-weight-bold">表單管理</h4>
        <div
          class="
            col-12 col-xl-8
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.FORM_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            :to="{ name: 'FormCreate' }"
          ><i class="fa fa-plus"></i>新增表單</b-button>
          <b-form-select
            class="ml-2"
            v-model="query.type"
            :options="typeOptions"
            @change="handlePageReset"
          ></b-form-select>
          <b-form-select
            class="ml-2"
            v-model="query.status"
            :options="statusOptions"
            @change="handlePageReset"
          ></b-form-select>
          <b-form-select
            class="ml-2"
            v-model="query.is_processing"
            :options="isProcessingOptions"
            @change="handlePageReset"
          ></b-form-select>

          <b-input-group class="ml-0 ml-xl-4">
            <b-form-input v-model="query.keyword" placeholder="搜尋" v-on:keyup.enter="handlePageReset"></b-form-input>
            <b-input-group-append>
              <b-button @click="handlePageReset">
                <i class="fa fa-search"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table striped hover responsive :items="forms" :fields="fields" @sort-changed="handleSort">
                <template #cell(start_end_time)="data">
                  <div>
                    {{ dateFormatter(data.item.start_at) }}
                  </div>
                  <div>
                    {{ dateFormatter(data.item.end_at) }}
                  </div>
                </template>
                <template #cell(status)="data">
                  <b-badge v-if="data.item.status == 'draft'" variant="warning">
                    草稿
                  </b-badge>
                  <b-badge
                    v-if="data.item.status == 'published'"
                    variant="success"
                  >
                    公開
                  </b-badge>
                  <b-badge
                    v-if="data.item.status == 'closed'"
                    variant="secondary"
                  >
                    關閉
                  </b-badge>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.FORM_EDIT])"
                    variant="inverse-dark"
                    @click="showFormUrl(data.item)"
                  >
                    <i class="fa fa-link"></i>
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.FORM_RESULT])"
                    variant="inverse-info"
                    :to="{
                      name: 'FormResultList',
                      params: { id: data.item.id },
                    }"
                  >
                    <span class="mdi mdi-note-text"></span> 填寫內容
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.FORM_CREATE])"
                    variant="inverse-info"
                    @click="copyFormData(data.item)"
                  >
                    <span class="mdi mdi-content-copy"></span> 複製
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.FORM_EDIT])"
                    variant="inverse-primary"
                    :to="{ name: 'FormRead', params: { id: data.item.id } }"
                  >
                    <span class="mdi mdi-eye"></span> 查看
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.FORM_EDIT])"
                    variant="inverse-warning"
                    :to="{ name: 'FormEdit', params: { id: data.item.id } }"
                  >
                    <span class="mdi mdi-lead-pencil"></span> 編輯
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.FORM_DELETE])"
                    variant="inverse-danger"
                    @click="deleteForm(data.item)"
                  >
                    <span class="mdi mdi-delete"></span> 刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-card-text>
    </b-card>

    <b-modal
      id="form-url-modal"
      footer-class="d-flex justify-content-center"
      hide-header
    >
      <h5 class="mb-2">填寫網址</h5>
      <b-textarea
        id="form-url"
        v-b-tooltip.click="'已複製'"
        v-model="formUrl"
        @click="copyFormUrl('#form-url')"
      ></b-textarea>
      <template v-if="!!checkinUrl">
        <h5 class="my-2">報到網址</h5>
        <b-textarea
          id="checkin-url"
          v-b-tooltip.click="'已複製'"
          v-model="checkinUrl"
          @click="copyFormUrl('#checkin-url')"
        ></b-textarea>
      </template>
      <template #modal-footer>
        <b-button
          size="lg"
          variant="danger"
          @click="$bvModal.hide('form-url-modal')"
          >關閉</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import { format } from "date-fns";
import formApi from "@/apis/form";
import { mapState } from "vuex";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { paginationMixin } from "@/mixins/pagination";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";

export default {
  components: { CustomPagination },
  mixins: [paginationMixin],
  data() {
    return {
      consts: consts,
      showLoading: false,
      forms: [],
      typeOptions: [
        { value: null, text: "全部類型" },
        { value: "survey", text: "問券" },
        { value: "event", text: "活動" },
      ],
      statusOptions: [
        { value: null, text: "全部表單" },
        { value: "draft", text: "草稿" },
        { value: "published", text: "公開" },
        { value: "closed", text: "關閉" },
      ],
      isProcessingOptions: [
        { text: "全部狀態", value: null },
        { text: "生效中", value: true },
        { text: "已失效", value: false },
      ],
      fields: [
        { key: "title", label: "表單標題", sortable: true },
        {
          key: "type",
          label: "類型",
          formatter: (value) => {
            if (value === "survey") {
              return "問券";
            } else if (value === "event") {
              return "活動";
            } else {
              return "";
            }
          },
        },
        { key: "status", label: "狀態", sortable: true },
        { key: "result_count", label: "填寫數量", sortable: true },
        { key: "start_end_time", label: "開始/結束時間" },
        { key: "actions", label: "管理" },
      ],
      totalRows: 0,
      formUrl: null,
      checkinUrl: null,
      initialized: false,
      query: {
        keyword: "",
        per_page: 10,
        page: 1,
        sort_by: "created_at",
        order_by: "desc",
        type: null,
        status: null,
        is_processing: null,
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      const defaultQuery = {
        type: null,
        status: null,
        is_processing: null,
      };
      this.query = { ...defaultQuery, ...to.query };
      this.fetchForms();
    },
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },
  async mounted() {
    await this.fetchForms();
    this.initialized = true;
  },
  methods: {
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async fetchForms() {
      try {
        const params = { ...this.query };
        let response = await formApi.getForms(params);
        this.forms = response.data.data;
        this.totalRows = response.data.meta.total;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取表單列表失敗", "error");
      }
    },
    async deleteForm(form) {
      try {
        this.showLoading = true;
        const result = await this.$swal({
          title: "你確定要刪除嗎？",
          html: `<br/>表單標題：${form.title}<br/><br/>刪除後，相關資料將無法回復，<br/>請您確認！<br/>`,
          type: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonColor: "#3085D7",
          cancelButtonText: "不刪除",
          confirmButtonColor: "#DD3333",
          confirmButtonText: "確定刪除",
          reverseButtons: true,
        });

        if (!result.value) {
          return;
        }

        await formApi.deleteForm(form.id);
        this.$swal("刪除成功", "", "success");
        await this.fetchForms();
      } catch (error) {
        console.error(error);
        this.$swal("刪除失敗", "", "error");
      } finally {
        this.showLoading = false;
      }
    },
    showFormUrl(form) {
      this.formUrl = `https://liff.line.me/${this.organization.liff_id}/form/${form.id}`;
      this.checkinUrl = form.type == 'event'
        ? `https://liff.line.me/${this.organization.liff_id}/form/${form.id}/checkin`
        : null;
      this.$bvModal.show("form-url-modal");
    },
    handleSort(ctx) {
      this.query.sort_by = ctx.sortBy;
      this.query.order_by = ctx.sortDesc ? "desc" : "asc";
      this.handlePageReset();
    },
    copyFormUrl(id) {
      const inputText = document.querySelector(id);
      inputText.select();
      document.execCommand("copy");
    },
    dateFormatter: (value) => {
      if (!value) return "無";
      return format(new Date(value), "yyyy-MM-dd HH:mm:ss");
    },
    async copyFormData(form) {
      this.$swal
        .fire({
          type: "warning",
          title: "請問是否複製表單<br>【"+ form.title+"】",
          confirmButtonText: "確定複製",
          cancelButtonText: "取消",
          showCancelButton: true,
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.showLoading = true;

              const response = await formApi.copyForm(form.id);
              if (response.status === 200) {
                await this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "已複製完成",
                });
                await this.fetchForms();
                this.showLoading = false;
              }
            } catch (error) {
              this.$swal("失敗", error.response.data.message, "複製失敗，請稍候再試");
            }
          }
        });
    },
  },
};
</script>
